import { useCallback, useEffect, useState } from "react";


import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { Dropdown, MenuProps } from 'antd';
import { UpOutlined, DownOutlined } from "@ant-design/icons";

import Tooltip from "@/shared/ui/Tooltip";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";

import styles from "./HistoryManager.module.scss";


const periodService = container.resolve(PeriodService);

export const Interval = observer(() => {
  const { get, set } = useSearchParamsTemplate();
  const qsInterval = get('interval');
  const { intervalOptions } = periodService;
  const defaultInterval = intervalOptions[Math.floor(intervalOptions.length / 2)];

  const getOpt = useCallback((key: string | null) => intervalOptions.find((itm) => itm.key === key),
    [intervalOptions]);
  const interval = getOpt(qsInterval) || defaultInterval;

  const [open, setOpen] = useState(false);

  const handleSelect: MenuProps['onSelect'] = ({ key }) => {
    periodService.interval = getOpt(key);
    set({ interval: key });
  };

  const StateIcon = open ? UpOutlined : DownOutlined;

  useEffect(() => {
    if (!interval) return;
    periodService.interval = interval;
    const { key } = interval;
    set({ interval: key });
  }, []);

  useEffect(() => {
    if (getOpt(qsInterval)) return;
    periodService.interval = defaultInterval;
    const { key } = defaultInterval;
    set({ interval: key });
  }, [qsInterval]);

  const icon = (
    <>
      {getOpt(qsInterval)?.label}
      &nbsp;
      <StateIcon />
    </>
  );

  return (
    <Tooltip title='Шаг запроса данных' placement="top" disableInteractive>
      <span>
        <Dropdown.Button
          className={styles['refresh-btn']}
          icon={icon}
          buttonsRender={([, btn]) => [null, btn]}
          menu={{
            items: intervalOptions,
            selectable: true,
            defaultSelectedKeys: [interval.key],
            onSelect: handleSelect,
          }}
          onOpenChange={setOpen}
        />
      </span>
    </Tooltip>
  );
});
