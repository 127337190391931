import * as React from 'react';
import { FC, memo } from 'react';

import { Controller } from 'react-hook-form';
import { get } from 'lodash';
import { Stack } from '@mui/system';
import { SelectOption } from '@mui/base';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { amendDraggable } from '@/shared/lib/extendDndkit';

import { Label } from '../../../Label/Label';

import styles from './FieldAutocomplete.module.scss';

export interface FieldSelectProps extends Omit<FieldProps, 'options' | 'error'> {
  options: SelectOption<string>[] | string[];
  error?: any,
  multiple?: boolean;
  renderOption?: any;
  getOptionLabel?: any;
}

/**
 * Мультиселект CronSelect для генератора форм
 */
export const FieldAutocomplete: FC<FieldSelectProps> = memo(
  ({ name, label, options, isRequired, description, error, control, multiple, defaultValue, props, onChange, renderOption, getOptionLabel }) => {
    return (
      <Stack spacing={1}>
        <Stack position='relative' gap={2}>
          <Label name={name} required={isRequired} tooltip={description}>
            {label}
          </Label>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => {
              return (
                <Autocomplete
                  value={field.value || []}
                  options={options}
                  autoComplete
                  freeSolo
                  multiple={multiple}
                  filterSelectedOptions
                  {...amendDraggable(true)}
                  {...props}
                  onChange={(event, value) => {
                    field.onChange(value);
                    onChange && onChange()
                  }}
                  onInputChange={(event, value) => {
                    if (multiple !== true) {
                        field.onChange(value)
                    }
                  }}
                  renderOption={renderOption}
                  getOptionLabel={getOptionLabel}
                />
              );
            }}
          />
        </Stack>
        {error && <p className={styles.helpText}>{error}</p>}
      </Stack>
    );
  }
);
