import { Edge } from "reactflow";
import { DisplayMessageService } from "@/entities/MessageTracing/api/DisplayMessageService";
import { AllConnectService } from "@/entities/Connect/services/AllConnectService";
import { MessageTree } from "../index";
import { getNodesForMessageTracing } from "./getNodesForMessageTracing";
import { getEdgesForMessageTracing } from "./getEdgesForMessageTracing";

export const buildMessageTree = (
  displayMessageService: DisplayMessageService,
  allConnectService: AllConnectService,
): MessageTree => {
  const nodes = getNodesForMessageTracing(displayMessageService, allConnectService);
  const edges: Edge[] = getEdgesForMessageTracing(displayMessageService);
  return { nodes, edges };
};
