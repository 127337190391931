import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import {
  ReactFlow,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { Box, Stack } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import MaterialTabs, { tabsClasses } from '@mui/material/Tabs';
import SplitPane from 'react-split-pane';
import cn from 'classnames';

import { DisplayMessageService } from '@/entities/MessageTracing/api/DisplayMessageService';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { ButtonClose } from '@/shared/ui/Button/ButtonClose/ButtonClose';
import { PaginationForTracingMessages } from '@/features';
import {
  MESSAGE_TRACING_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { RequestFilter } from '@/entities/Flow/types';
import { SwitchWithLabel } from '@/shared/ui/Switch/SwitchWithLabel';
import { ResendMessageErrorButton } from '@/features/ResendMessageError';
import { Tabs, MessageNodeProps } from '@/entities/MessageTracing';
import { Tab } from '@/shared/ui/Tab/Tab';
import Typography from '@/shared/ui/Typography';
import { TabPanel } from '@/shared/ui/TabPanel/TabPanel';
import { ExecutorLogView } from '@/entities/ExecutorLog';
import ReactFlowToImageButton from '@/shared/ui/ReactFlowToImageButton';
import { AllConnectService } from '@/entities/Connect/services/AllConnectService';
import useLocalStorage from '@/hooks/useLocalStorage';
import { FlowPlatform } from '@/shared/ui/FlowPlatform/FlowPlatform';
import { buildMessageTree } from "@/entities/MessageTracing/adapters/buildMessageTree";
import { MessageNode } from './MessageNode/MessageNode'; // don't shorten
import { BlockNode } from './BlockNode/BlockNode';
import styles from './MessageTracingWorkspace.module.scss';

export interface MessageTracingWorkspaceProps {}

const proOptions = {
  hideAttribution: true,
};
const nodeTypes = { messageNode: MessageNode };
const proBlockOptions = {
  hideAttribution: true,
};
const nodeBlockTypes = { blockNode: BlockNode };

const displayMessageService = container.resolve(DisplayMessageService);
const allConnectService = container.resolve(AllConnectService);

const defaultSize = 200;
const minSize = 100;

const errorFilter = {
  name: 'isFailed',
  operator: 'is',
  value: true,
};

export const MessageTracingWorkspace: FC<MessageTracingWorkspaceProps> = observer(() => {
  const { get, set, remove } = useSearchParamsTemplate();

  const [size, setSize] = useLocalStorage('messageTracingWorkspace_heightBlockTrace', defaultSize);
  const [isTrace, setIsTrace] = useLocalStorage('messageTracingWorkspace_isTrace', true);

  const [nodes, setNodes] = useNodesState<MessageNodeProps>([]);
  const [edges, setEdges] = useEdgesState([]);

  const { nodes: nodesBlock, edges: edgesBlock } = displayMessageService.blockTree;

  const [filter, setFilter] = useState<RequestFilter>(null);
  const filterList = filter ? [filter] : [];

  const [expanded, setExpanded] = useState(true);

  const blockId = get(SearchParams.BlockId);
  const isError = get(SearchParams.IsError);
  const messageId = get(SearchParams.MessageId);

  const prevSize = useRef(defaultSize);
  const direction = useRef(defaultSize);

  const { updateData } = displayMessageService;

  useEffect(() => {
    setFilter(isError === 'true' ? errorFilter : null);
  }, [isError]);

  useEffect(() => {
    if (!updateData) return;
    const { nodes: n } = buildMessageTree(displayMessageService, allConnectService);
    setNodes(n);
  }, [updateData]);

  useEffect(() => {
    displayMessageService
      .getMessages(blockId, {
        pagination: { size: 1, page: 0 },
        filterList,
        addTrace: isTrace,
        messageId,
      })
      .then(() => {
        const { nodes: n, edges: e } = buildMessageTree(displayMessageService, allConnectService);// fixme move to computed
        setNodes(n);
        setEdges(e);
      });
  }, [filter, blockId, messageId]);

  useEffect(() => {
    if (!displayMessageService.selectedExecutorHistoryId) return;
    const { nodes: n, edges: e } = buildMessageTree(displayMessageService, allConnectService);// fixme move to computed
    setNodes(n);
    setEdges(e);
  }, [displayMessageService.selectedExecutorHistoryId]);

  useEffect(
    () => () => {
      displayMessageService.reset();
    },
    []
  );

  const handleTabClick = useCallback(
    (tab: Tabs) => () => {
      displayMessageService.setActiveTab(tab);
    },
    []
  );

  const handleButtonCloseClick = useCallback(() => {
    remove([SearchParams.ShowMessageTracing, ...MESSAGE_TRACING_SEARCH_PARAMS_TEMPLATE]);
  }, [remove]);

  const handleIsErrorChange = useCallback(
    (e: any) => {
      set([SearchParams.IsError, { [SearchParams.IsError]: e.target.checked.toString() }]);
    },
    [set]
  );
  const handleChange = (heigth: number) => {
    direction.current = heigth - prevSize.current;
    prevSize.current = heigth;
  };
  const wrapAnimation = (height: number) => {
    setExpanded(false);
    setSize(height);
    setTimeout(() => {
      setExpanded(true);
    }, 750);
  };

  const calcHeight = (h: number) => {
    if (h > minSize * 0.7) return minSize;
    if (h < minSize * 0.3) return 0;
    return direction.current > 0 ? minSize : 0;
  };

  const handleDragFinish = (height: number) => {
    if (height === 0 || height >= minSize) {
      setSize(height);
      return;
    }
    const newHeight = calcHeight(height);
    wrapAnimation(newHeight);
  };

  const handleDoubleClickResizer = () => {
    wrapAnimation(size < minSize ? minSize : 0);
  };

  const toggleIsTrace = () => {
    setIsTrace(!isTrace);
  };

  const renderTabs = () =>
    displayMessageService.selectedExecutorHistory?.executorLogId ? (
      <MaterialTabs
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs'
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <Tab
          isActive={displayMessageService.activeTab === Tabs.tracing}
          onClick={handleTabClick(Tabs.tracing)}
        >
          <Typography size='small'>Трассировка</Typography>
        </Tab>
        <Tab
          isActive={displayMessageService.activeTab === Tabs.logs}
          onClick={handleTabClick(Tabs.logs)}
        >
          <Typography size='small'>Лог сообщения</Typography>
        </Tab>
      </MaterialTabs>
    ) : null;

  return (
    <Stack padding='10px 0 10px 10px' height='100%' gap={1}>
      <Stack
        margin='0px 10px -10px 10px'
        paddingRight='20px'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        gap={1.5}
      >
        <Box component='h2' fontWeight={900} fontSize='2.5rem'>
          Мониторинг
        </Box>
        <Stack direction='row' justifyContent='space-between' gap={1.5}>
          {!messageId ? (
            <SwitchWithLabel checked={isTrace} label='Трассировка' onChange={toggleIsTrace} />
          ) : null}
          <ButtonClose onClose={handleButtonCloseClick} width={40} height={40} />
        </Stack>
      </Stack>
      {displayMessageService.isLoadingMessages ? (
        <Box height='100%' alignItems='center' justifyContent='center' display='flex'>
          <LoaderIcon width={24} height={24} />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100px',
              position: 'relative',
            }}
          >
            <SplitPane
              split='horizontal'
              minSize={0}
              maxSize={-20}
              defaultSize={size}
              size={size}
              allowResize={expanded}
              className={styles.splitPane}
              resizerClassName={cn(styles.resizer, styles.horizontal)}
              onDragStarted={() => { setSize(undefined); }}
              onChange={handleChange}
              onDragFinished={handleDragFinish}
              onResizerDoubleClick={handleDoubleClickResizer}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                }}
              >
                <ReactFlow
                  nodeTypes={nodeBlockTypes}
                  proOptions={proBlockOptions}
                  onlyRenderVisibleElements={nodesBlock.length > 30}
                  nodes={nodesBlock}
                  edges={edgesBlock}
                  defaultViewport={{ x: 0, y: 0, zoom: 0.75 }}
                  className='blockTracing'
                />
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {renderTabs()}
                <>
                  <TabPanel
                    index={Tabs.tracing}
                    value={displayMessageService.activeTab}
                    height='100%'
                  >
                    {!displayMessageService.exchangeList && <p>Сообщения отсутствуют</p>}
                    <Box height='100%'>
                      <FlowPlatform
                        nodeTypes={nodeTypes}
                        proOptions={proOptions}
                        nodes={nodes}
                        edges={edges}
                        defaultViewport={{ x: 0, y: 50, zoom: 0.75 }}
                        onlyRenderVisibleElements={nodes.length > 30}
                        translateExtent={[
                          [-100, -100],
                          [
                            (displayMessageService.maxWidth || Infinity) + 1000,
                            (displayMessageService.maxHeight || Infinity) + 1000,
                          ],
                        ]}
                        className='messageTracing'
                      >
                        {!!nodes.length && nodes.length <= 30 && (
                          <ReactFlowToImageButton
                            selector='.messageTracing'
                            imageWidth={displayMessageService.maxWidth || 1000}
                            imageHeight={displayMessageService.maxHeight || 1000}
                          />
                        )}
                      </FlowPlatform>
                    </Box>
                  </TabPanel>
                  {displayMessageService.selectedExecutorHistory?.executorLogId && (
                    <TabPanel index={Tabs.logs} value={displayMessageService.activeTab} height='100%'>
                      <ExecutorLogView
                        blockId={blockId}
                        id={displayMessageService.selectedExecutorHistory?.executorLogId}
                      />
                    </TabPanel>
                  )}
                </>
              </Box>
            </SplitPane>
          </Box>
          {!messageId && (
            <Box display='flex' flexDirection='column' gap='1rem'>
              <Box display='flex' justifyContent='space-between' flexGrow={1} margin='0 1rem'>
                <SwitchWithLabel
                  checked={isError === 'true'}
                  label='Показать только с ошибками'
                  onChange={handleIsErrorChange}
                />
                <Box width={300} alignSelf='flex-end'>
                  <ResendMessageErrorButton
                    messageErrorId={displayMessageService.selectedExecutorHistory?.id || ''}
                  />
                </Box>
              </Box>
              <Box display='flex' justifyContent='center'>
                <PaginationForTracingMessages
                  blockId={blockId}
                  filterList={filterList}
                  isTrace={isTrace}
                />
              </Box>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
});

MessageTracingWorkspace.displayName = 'MessageTracingWorkspaceProps';
