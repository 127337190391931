import { FC, useCallback } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { UpsertExternalAppService } from '../api/UpsertExternalAppService';

import styles from './styles.module.scss';
import { OpenApiService } from '@/entities/OpenApi/features/api/OpenApiService';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

const upsertExternalAppService = container.resolve(UpsertExternalAppService);
const openApiService = container.resolve(OpenApiService);

export interface CreateServiceAppCardProps {
  service?: string;
}

export const CreateServiceAppCard: FC<CreateServiceAppCardProps> = observer(({ service }) => {
  const { remove } = useSearchParamsTemplate();
  const handleClick = useCallback(() => {
    if (service === 'openApi') {
      openApiService.open = true;
      openApiService.selectedId = null;
      remove([SearchParams.OpenApiId]);
    }
    if (service === 'external') upsertExternalAppService.open = true;
  }, []);

  return (
    <Card className={styles.root} variant='outlined' onClick={handleClick}>
      <CardContent sx={{ height: '100%', minWidth: 300, minHeight: 240 }}>
        <Stack direction='row' gap={1.5} alignItems='center' justifyContent='center' height='100%'>
          <AddBoxOutlinedIcon />
          Добавить сервис
        </Stack>
      </CardContent>
    </Card>
  );
});

CreateServiceAppCard.displayName = 'CreateServiceAppCard';
