import { FC } from 'react';

import { Controller } from 'react-hook-form';
import { Stack } from '@mui/system';
import { Label } from '@/shared/ui/Label/Label';
import { InputNumber } from '@/shared/ui/InputNumber/InputNumber/InputNumber';
import { FieldProps } from '@/shared/ui/Fields/Fields';

import styles from '../FieldInput/FieldInput.module.scss';
import { amendDraggable } from "@/shared/lib/extendDndkit";

interface FieldInputNumberProps extends  Omit<FieldProps, 'register' | 'error'> {
  error?: any;
  min?: number;
  max?: number;
}

export const FieldInputNumber: FC<FieldInputNumberProps> = ({
  label,
  name,
  type,
  isRequired,
  description,
  error,
  control,
  defaultValue,
  ...props
}) => (
  <Stack spacing={1} flex={1} {...amendDraggable(true)}>
    <Stack spacing={2}>
      <Label name={name} tooltip={description} required={isRequired}>
        {label}
      </Label>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <InputNumber
            {...props}
            error={error}
            type={type}
            value={field.value}
            defaultValue={defaultValue}
            onChange={(value: string) => {
              field.onChange(value);
            }}
          />
        )}
      />
    </Stack>
    <p className={styles.helpText}>{error?.message}</p>
  </Stack>
);
