import { useCallback } from 'react';
import { get } from 'lodash';
import { closestCenter, DndContext, DragEndEvent, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { MouseSensor, TouchSensor } from '@/shared/lib/extendDndkit';

interface DraggableListProps {
  draggable: boolean;
  items: { id: string }[];
  onReorder: (from: number, to: number) => void;
  renderItem: ({ id }: { id: string }, index: number) => JSX.Element;
}

export function DraggableList({ draggable, items, onReorder, renderItem }: DraggableListProps) {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id === over.id) return;

    const from = get(active, 'data.current.sortable.index');
    const to = get(over, 'data.current.sortable.index');
    onReorder(from, to);
  }, []);

  const children = items.map((itm, idx) => renderItem(itm, idx));
  if (!draggable) return children;

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
}
