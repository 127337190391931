import { FC, memo } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import {
  arrayEmptyRuleByArray,
  arrayStatus,
  arrayStatusByObjectByArray,
  arrayType,
} from '@/shared/ui/Fields/components/MappingData/constants';
import { Controller } from 'react-hook-form';
import { MapObject } from '@/shared/ui/Fields/components/MappingData/MapObject';

export interface MapArrayProps {
  control: any;
  index: number;
  name: string;
  register: any;
}

export const MapArray: FC<MapArrayProps> = memo(({ control, name, index, register }) => {
  const renderTypeDataArray = () => {};

  return (
    <>
      <Grid xs={12} sm={6} lg={4} xl={3}>
        <FieldSelect
          label='Тип данных элемента массива'
          control={control}
          options={arrayType}
          error=''
          name={`${name}.${index}.arrayElement.type`}
        />
      </Grid>
      <Controller
        control={control}
        name={`${name}.${index}.arrayElement.type`}
        render={({ field }) => (
          <Grid xs={12} sm={6} lg={4} xl={3}>
            <FieldSelect
              label='Действие с элементом массива'
              control={control}
              options={field.value === 'object' ? arrayStatusByObjectByArray : arrayStatus}
              error=''
              name={`${name}.${index}.arrayElement.status`}
            />
          </Grid>
        )}
      />
      <Grid xs={12} sm={6} lg={4} xl={3}>
        <FieldSelect
          label='Если массив отсутствует'
          control={control}
          options={arrayEmptyRuleByArray}
          error=''
          name={`${name}.${index}.emptyRule`}
        />
      </Grid>
      <Controller
        control={control}
        name={`${name}.${index}.arrayElement.type`}
        render={({ field }) => {
          if (field.value === 'object') {
            return (
              <MapObject
                control={control}
                name={`${name}.${index}.arrayElement.children`}
                register={register}
              />
            );
          }

          return <span />;
        }}
      />
    </>
  );
});

MapArray.displayName = 'MapArray';
