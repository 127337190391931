import { Edge } from "reactflow";
import { flatten, groupBy, isEmpty, partition } from "lodash";
import { DisplayMessageService } from "@/entities/MessageTracing/api/DisplayMessageService";
import { AllConnectService } from "@/entities/Connect/services/AllConnectService";
import { normalize } from "@/shared/lib/normalize";
import { MessageTree } from "../index";
import { getNodesForMessageTracing } from "./getNodesForMessageTracing";
import { getEdgesForMessageTracing } from "./getEdgesForMessageTracing";
import { wrapBuilder } from './wrapBuilder';

export const buildMessageTree = (
  displayMessageService: DisplayMessageService,
  allConnectService: AllConnectService,
): MessageTree => {
  const nodes = getNodesForMessageTracing(displayMessageService, allConnectService);
  const { entities: nodeMap } = normalize(nodes, 'id');
  const edges: Edge[] = getEdgesForMessageTracing(displayMessageService);
  const [largeNet, commonNet] = partition(groupBy(edges, 'source'), (arr) => arr.length > 1);

  if (isEmpty(largeNet)) return { nodes, edges };


  const getNodeById = (id: string) => nodeMap[id];
  const wrapperList = largeNet.map(wrapBuilder(getNodeById, 60));
  const wrapperEdges = wrapperList.map(({ edge }) => edge);
  return {
    nodes: [...nodes, ...wrapperList],
    edges: [...flatten(commonNet), ...wrapperEdges],
  };
};
