import { Node, Edge } from "reactflow";
import { last } from "lodash";

export const wrapBuilder = (
  getNodeById: (id: string) => Node,
  wrapSize: number,
) => (list: Edge[]) => {
  const node1 = getNodeById(list[0].target);
  const node2 = getNodeById(list[1].target);
  const nodeLast = getNodeById(last(list).target);
  const { style, position, id } = node1;
  const { position: lastPosition } = nodeLast;
  const x = wrapSize / 2;
  const y = wrapSize / 2;
  const yOffset = node2.position.y - node1.position.y;

  // update grouped nodes
  list.forEach(({ target }, idx) => {
    const node = getNodeById(target);
    node.parentNode = `group-${node1.id}`;
    node.extent = 'parent';
    node.position = { x, y: y + yOffset * idx };
  });

  const [edge1] = list;
  // group wrap node
  return {
    id: `group-${id}`,
    edge: edge1,
    position: {
      x: position.x - wrapSize / 2,
      y: position.y - wrapSize / 2,
    },
    type: 'output',
    zIndex: -1,
    draggable: false,
    data: null,
    style: {
      width: parseInt(style.width, 10) + wrapSize,
      height: lastPosition.y - position.y + parseInt(style.height, 10) + wrapSize,
      display: 'flex',
      border: '2px solid var(--dark-primary-color)',
      borderRadius: '12px',
      opacity: 0.05,
    },
  };
};