import { flatten, groupBy, isEmpty, partition } from "lodash";
import { normalize } from '@/shared/lib/normalize';
import { ExecutorHistory } from "@/entities/Connect/types";
import { wrapBuilder } from "@/entities/MessageTracing/adapters/wrapBuilder";
import { BlockTree } from "./types";
import { getNodesForBlockTracing } from "./getNodesForBlockTracing";
import { getEdgesForBlockTracing } from "./getEdgesForBlockTracing";

export const buildBlockTree = (executorHistory: ExecutorHistory): BlockTree => {
  const nodes = getNodesForBlockTracing(executorHistory);
  const edges = getEdgesForBlockTracing(executorHistory);
  const groupedEdges = groupBy(edges, 'source');
  const [largeNet, commonNet] = partition(groupedEdges, (arr) => arr.length > 1);

  if (isEmpty(largeNet)) return { nodes, edges };

  const { entities: nodeMap } = normalize(nodes, 'id');
  const getNodeById = (id: string) => nodeMap[id];
  const wrapperNodes = largeNet.map(wrapBuilder(getNodeById, 20));

  const wrapperEdges = wrapperNodes.map(({ edge }) => edge);
  return {
    nodes: [...nodes, ...wrapperNodes],
    edges: [...flatten(commonNet), ...wrapperEdges],
  };
}
