import React, {
  ChangeEventHandler,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Stack } from '@mui/system';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import Tooltip from '@/shared/ui/Tooltip';
import cn from 'classnames';
import { NodeProps, NodeResizer, ResizeDragEvent, ResizeParams, useStore } from 'reactflow';
import { motion, Variants } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { BlockRelationService } from '@/entities/Block/services/BlockRelationService';
import { HandleListBetweenBlocks } from '@/entities/Block/components/HandleListBetweenBlocks/HandleListBetweenBlocks';
import { Switch } from '@/shared/ui/Switch/Switch';
import { ConnectionHandle } from '@/entities/Block/components/ConnectionHandle/ConnectionHandle';
import { OpenBlockForm, OpenBlockTesting, OpenDeleteBlock, OpenSendMessage } from '@/features';
import { OpenMessageQueue } from '@/features/OpenMessageQueue';
import { OpenMessageTracing } from '@/features/OpenMessageTracing';
import { RunOnKeys } from '@/shared/ui/RunOnKeys/RunOnKeys';
import { RegistrableValues } from '@/shared/lib/types';
import { FlowCanvasService } from '@/entities/Flow/services/FlowCanvasService';
import { withConsumerInformation } from '@/entities/ConsumerInformation';
import { Snackbar } from '@mui/material';

import { ConnectList } from '../Connect/components/ConnectList/ConnectList';
import { ConnectTypes } from '../Connect/components/ConnectList/types';
import { BlockService } from './services/BlockService';
import { ConnectService } from '../Connect/services/ConnectService';
import { AllConnectService } from '../Connect/services/AllConnectService';
import { BlockMonitoringService } from './services/BlockMonitoringService';

import styles from './Block.module.scss';
import { ManyToOneIcon } from '@/shared/ui/Icons/ManyToOneIcon/ManyToOneIcon';
import { KEYBOARD_KEYS } from '@/shared/lib/constants';
import { OpenExecutorLogBlock } from '@/features/OpenExecutorLogBlock';
import { wrapLinksInAnchors } from '@/shared/lib/htmlUtils';
import { IndicatorGuaranteedDelivery } from '@/features/IndicatorGuaranteedDelivery/ui/IndicatorGuaranteedDelivery';
import { IndicatorGuaranteedOrder } from '@/features/IndicatorGuaranteedOrder/IndicatorGuaranteedOrder';

export type BlockProps = NodeProps;

const variants: Variants = {
  open: {
    x: '0%',
  },
  close: {
    x: '100%',
  },
};

const allConnectService = container.resolve(AllConnectService);
const joyrideService = container.resolve(JoyRideService);

const BlockBasic: FC<BlockProps> = observer(({ id, data: { index } }) => {
  const { flowId, blockId } = useParams();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isNto1, setIsNto1] = useState<boolean>(false);
  const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
  const [inFocus, setInFocus] = useState<boolean>(false);
  const [isSelectedBlock, setIsSelectedBlock] = useState<boolean>(false);
  const [isClickActive, setIsClickActive] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = React.useState<boolean>(false);

  const intervalId = useRef<number>();

  const connectService = useMemo(() => {
    container.register(RegistrableValues.BlockId, { useValue: id });
    return container.resolve(ConnectService);
  }, [id]);

  const { blockService, blockMonitoringService, blockRelationService, flowCanvasService } =
    useMemo(() => {
      container.register(RegistrableValues.FlowId, { useValue: flowId || '' });

      const blockService = container.resolve(BlockService);
      const blockMonitoringService = container.resolve(BlockMonitoringService);
      const flowCanvasService = container.resolve(FlowCanvasService);
      const blockRelationService = container.resolve(BlockRelationService);

      return {
        blockService,
        blockMonitoringService,
        flowCanvasService,
        blockRelationService,
      };
    }, [flowId]);

  const connectionHandleId = useStore(useCallback((store) => store.connectionHandleId, []));

  const block = blockService.blocks?.entities[id];

  const isConnectable = useMemo(() => {
    return (
      blockService.isVisibleConnectionLine &&
      !blockService.isVisiblePanelChooseConnects &&
      connectionHandleId !== id
    );
  }, [
    blockService.isVisibleConnectionLine,
    blockService.isVisiblePanelChooseConnects,
    connectionHandleId,
    id,
  ]);

  const handleSwitchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (block) {
        blockService.updateBlock({ ...block, isActive: event.target.checked });

        setIsActive(event.target.checked);
      }
    },
    [block, blockService]
  );

  const handleBlockManytoOneChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (block) {
        blockService.updateBlock({ ...block, isManyToOne: event.target.checked });

        setIsNto1(event.target.checked);
      }
    },
    [block, blockService]
  );

  const handleClickIsActive = () => {
    setIsClickActive(!isClickActive);
    if (selectedBlocks.includes(id)) {
      setSelectedBlocks(selectedBlocks.filter((blockId) => blockId !== id));
    } else {
      setSelectedBlocks([...selectedBlocks, id]);
    }
  };

  const onNodeResize = useCallback(
    (event: ResizeDragEvent, params: ResizeParams) => {
      const { width, height, x, y } = params;

      if (flowId) {
        if (id) {
          flowCanvasService.updateElement(id, y, x, height, width);
        }
      }
    },
    [flowCanvasService, flowId, id]
  );

  const handleBlockMouseEnter = (): void => {
    if (!selectedBlocks.includes(id)) {
      setIsActive(true);
    }
    if (joyrideService.run) {
      setIsActive(true);
    }
  };

  const handleBlockMouseLeave = (): void => {
    if (!selectedBlocks.includes(id)) {
      setIsActive(false);
    }
    if (joyrideService.run) {
      setIsActive(true);
    }
  };
  /**  Закрытие снэкбара */
  const closeSnackbar = () => {
    setIsOpenSnackbar(false);
  };

  useEffect(() => {
    const status = block?.id === blockId;

    setIsSelectedBlock(status);
    setIsActive(status);
  }, [block?.id, blockId]);

  useEffect(() => {
    if (!block?.isActive) {
      setIsActive(false);
    }
  }, [block?.isActive]);

  useEffect(() => {
    void blockMonitoringService.get();

    intervalId.current = window.setInterval(() => {
      void blockMonitoringService.get();
    }, 10000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [blockMonitoringService]);

  useEffect(() => {
    setIsActive(selectedBlocks.includes(id));
  }, [selectedBlocks, id]);

  const { Alt, Shift, Backspace, Control, Delete, Digit1, Digit2, Digit3, A, C, Q, W, M, R, T, V } =
    KEYBOARD_KEYS;
  const keysEvents = [
    {
      keys: [Alt, Shift, Digit1],
      event: () => eventEmitter.emit(ConnectTypes.Input + block?.id),
    },
    {
      keys: [Alt, Shift, Digit2],
      event: () => eventEmitter.emit(ConnectTypes.Processor + block?.id),
    },
    {
      keys: [Alt, Shift, Digit3],
      event: () => eventEmitter.emit(ConnectTypes.Output + block?.id),
    },
    {
      keys: [Alt, A],
      event: () => {
        if (block) {
          blockService.updateBlock({ ...block, isActive: !block?.isActive });
          setIsActive(!block?.isActive);
        }
      },
    },
    {
      keys: [Control, C],
      event: async () => {
        if (block) {
          const { sx } = flowCanvasService.elements?.entities[block.id];
          await navigator.clipboard.writeText(JSON.stringify({ block: block, sx: sx }));
          setIsOpenSnackbar(true);
        }
      },
    },
    {
      keys: [Alt, Q],
      event: () => eventEmitter.emit(`OpenBlockForm${block?.id}`),
    },
    {
      keys: [Alt, W],
      event: () => eventEmitter.emit(`OpenBlockTesting${block?.id}`),
    },
    {
      keys: [Alt, M],
      event: () => eventEmitter.emit(`OpenSendMessage${block?.id}`),
    },
    {
      keys: [Alt, R],
      event: () => eventEmitter.emit(`OpenMessageQueue${block?.id}`),
    },
    {
      keys: [Alt, T],
      event: () => eventEmitter.emit(`OpenMessageErrorButton${block?.id}`),
    },
    {
      keys: [Alt, V],
      event: () => eventEmitter.emit(`OpenMessageTracing${block?.id}`),
    },
    {
      keys: [Delete],
      event: () => eventEmitter.emit(`OpenDeleteBlock${block?.id}`),
    },
    {
      keys: [Backspace],
      event: () => eventEmitter.emit(`OpenDeleteBlock${block?.id}`),
    },
  ];

  const renderLogicBlock = () => {
    if (connectService.exProcessorList.ids.length > 0) {
      return (
        <>
          <div className={styles.section_connect}>
            <ConnectList
              title='Обработчики'
              connectors={connectService.processorList}
              connectorCreatorOptions={allConnectService.processorOptions}
              type={ConnectTypes.Processor}
              blockId={block?.id || ''}
              id={`addHandler-${id}`}
            />
          </div>
          <div className={styles.section_connect}>
            <ConnectList
              title='Коннекторы исходящих данных'
              connectors={connectService.outputList}
              connectorCreatorOptions={allConnectService.outputOptions}
              type={ConnectTypes.Output}
              blockRelationService={blockRelationService}
              blockId={block?.id || ''}
              id={`addInOutput-${id}`}
            />
          </div>
          <div className={styles.bottom_section}>
            <ConnectList
              title='В случае ошибки'
              connectors={connectService.exProcessorList}
              connectorCreatorOptions={allConnectService.processorOptions}
              type={ConnectTypes.ExProcessor}
              blockId={block?.id || ''}
              id={`addExHandler-${id}`}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.section_connect}>
            <ConnectList
              title='Обработчики'
              connectors={connectService.processorList}
              connectorCreatorOptions={allConnectService.processorOptions}
              type={ConnectTypes.Processor}
              blockId={block?.id || ''}
              id={`addHandler-${id}`}
            />
          </div>
          <div className={styles.bottom_section}>
            <ConnectList
              title='Коннекторы исходящих данных'
              connectors={connectService.outputList}
              connectorCreatorOptions={allConnectService.outputOptions}
              type={ConnectTypes.Output}
              blockRelationService={blockRelationService}
              blockId={block?.id || ''}
              id={`addInOutput-${id}`}
            />
          </div>
        </>
      );
    }
  };

  return (
    <>
      <NodeResizer
        nodeId={id}
        color={block?.isManyToOne ? '#FFE2AC' : '#6C5DD3FF'}
        lineStyle={{
          borderWidth: '10px',
          borderColor: block?.isManyToOne ? '#FFE2AC' : '#6C5DD3FF',
          opacity: 0,
        }}
        handleStyle={{ width: '16px', height: '16px', opacity: 0 }}
        isVisible
        minWidth={400}
        minHeight={400}
        onResize={onNodeResize}
      />
      <RunOnKeys
        keysEvents={keysEvents}
        tabIndex={1!}
        onMouseEnter={handleBlockMouseEnter}
        onMouseLeave={handleBlockMouseLeave}
        id={`block_${id}`}
        className={cn(styles.clicked, 'block', {
          [styles.clicked]: isClickActive || isActive,
        })}
        onFocus={() => setInFocus(true)}
        onBlur={() => setInFocus(false)}
      >
        <ConnectionHandle id={id} visible={!blockService.isVisiblePanelChooseConnects} />
        <HandleListBetweenBlocks
          connectIds={connectService.inputList.ids}
          relationPositions={blockRelationService.relationPositions}
          blockId={block?.id || ''}
          handlesType='target'
          lineIdWhichHover={blockRelationService.lineIdWhichHover}
        />
        <HandleListBetweenBlocks
          connectIds={connectService.outputList.ids}
          relationPositions={blockRelationService.relationPositions}
          blockId={block?.id || ''}
          handlesType='source'
          lineIdWhichHover={blockRelationService.lineIdWhichHover}
        />
        <div className={styles.root} id={`block_${index}`}>
          <Stack direction='row' sx={{ height: '100%' }}>
            <div
              className={cn(styles.monitoring, {
                [styles.active]: isActive || inFocus,
                [styles.inactive]: !block?.isActive,
                [styles.isManyToOne]: block?.isManyToOne,
              })}
            >
              <div className={styles.monitoringWrapper}>
                <Tooltip
                  title={`Количество успешно обработанных сообщений: ${
                    blockMonitoringService.monitoring?.successTotal || '0'
                  }`}
                  disableInteractive
                  placement='left'
                >
                  <span className={styles.success}>
                    {blockMonitoringService.monitoring?.successTotal.toLocaleString() || 0}
                  </span>
                </Tooltip>
                <Tooltip
                  title={`Количество сообщений с ошибками: ${
                    blockMonitoringService.monitoring?.failTotal || '0'
                  }`}
                  disableInteractive
                  placement='left'
                >
                  <span className={styles.error}>
                    {blockMonitoringService.monitoring?.failTotal.toLocaleString() || 0}
                  </span>
                </Tooltip>
              </div>
            </div>
            <motion.div
              className={cn(styles.toolbar, {
                [styles.active]: isActive || inFocus,
                [styles.inactive]: !block?.isActive,
                [styles.isManyToOne]: block?.isManyToOne,
              })}
              variants={variants}
              initial='close'
              animate={isActive || inFocus ? 'open' : 'close'}
              transition={{ ease: 'easeInOut', duration: 0.2 }}
            >
              {isActive || inFocus || joyrideService.run ? (
                <Stack justifyContent='space-between'>
                  <OpenBlockForm blockId={id} />
                  <OpenBlockTesting blockId={id} />
                  <OpenSendMessage blockId={id} />
                  <OpenMessageQueue blockId={id} />
                  <OpenMessageTracing blockId={id} />
                  <OpenExecutorLogBlock blockId={id} />
                  <OpenDeleteBlock blockId={id} />
                </Stack>
              ) : null}
            </motion.div>
            <div
              className={cn(styles.body, {
                [styles.active]: isActive || inFocus,
                [styles.inactive]: !block?.isActive,
                [styles.isManyToOne]: block?.isManyToOne,
              })}
            >
              <div className={styles.section_title}>
                <Stack spacing={1} className={styles.title_root}>
                  <p className={cn(styles.title, { [styles.inactive]: !block?.isActive })}>
                    {block?.name}
                  </p>
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: wrapLinksInAnchors(block?.description) }}
                  />
                </Stack>
                {block?.isManyToOne && (
                  <Stack>
                    <ManyToOneIcon />
                  </Stack>
                )}
              </div>

              <div className={styles.section}>
                <Stack width='100%' spacing={2}>
                  <Stack spacing={2.5}>
                    <Stack width='100%' direction='row' justifyContent='space-between'>
                      <p>
                        Статус:{' '}
                        <span
                          className={cn(styles.status, {
                            [styles.success]: block?.isActive,
                            [styles.error]: !block?.isActive,
                          })}
                        >
                          {block?.isActive ? 'Активен' : 'Неактивен'}
                        </span>
                      </p>
                      <Switch
                        checked={block?.isActive}
                        inputProps={{ tabIndex: 1 }}
                        onChange={handleSwitchChange}
                      />
                    </Stack>
                    <Stack
                      width='100%'
                      direction='row'
                      justifyContent='space-between'
                      spacing={1.5}
                    >
                      <IndicatorGuaranteedDelivery blockId={id} />
                      <IndicatorGuaranteedOrder blockId={id} />
                    </Stack>
                  </Stack>
                </Stack>
              </div>
              <div className={styles.section_connect} id='addInConnector'>
                <ConnectList
                  title='Коннекторы входящих данных'
                  connectors={connectService.inputList}
                  connectorCreatorOptions={allConnectService.inputOptions}
                  type={ConnectTypes.Input}
                  blockRelationService={blockRelationService}
                  blockId={block?.id || ''}
                  id={`addInInput-${id}`}
                />
              </div>
              {renderLogicBlock()}
            </div>
          </Stack>
        </div>
        <Snackbar
          className={styles.snackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpenSnackbar}
          autoHideDuration={1500}
          onClose={() => setIsOpenSnackbar(false)}
          message='Блок скопирован'
        />
      </RunOnKeys>
    </>
  );
});

export const Block = withConsumerInformation(BlockBasic);
