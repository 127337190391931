import { injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { isString, set, map } from 'lodash';
import dayjs from 'dayjs';

import { WDay } from '@/entities/Dashboard/types';
import { parse, isMathString } from '../components/HistoryManager/datemath';
import { baseQntDots, intervalMap } from '../components/HistoryManager/constants';
import { PeriodStore } from '../stores/PeriodStore';

@injectable()
export class PeriodService {
  constructor(private periodStore: PeriodStore) {
    makeAutoObservable(this);
  }

  get period(): PeriodStore['period'] {
    return this.periodStore.period;
  }

  set period(period) {
    this.periodStore.period = period;
  }

  get interval() {
    return this.periodStore.interval;
  }

  set interval(interval) {
    this.periodStore.interval = interval;
  }

  get duration(): number {
    const [start, end] = this.period;
    return end.diff(start, 's');
  }

  private getIdxBaseInterval(): number {
    // find the most slim interval option to selected period
    if (this.duration < 1500) return 2;
    const calcInterval = this.duration / baseQntDots;
    const intervalList = map(intervalMap, 'value').sort((a, b) => a - b);
    const relations = intervalList.map((interval) => Math.abs(interval - calcInterval));
    return relations.indexOf(Math.min(...relations));
  }

  get intervalOptions() {
    const idx = this.getIdxBaseInterval();
    const intervalList = map(intervalMap, 'value').sort((a, b) => a - b);
    const restrictedList = intervalList.slice(idx - 2, idx + 2);
    return restrictedList.map((interval) => intervalMap[String(interval)]);
  }

  get mode() {
    const { period } = this.periodStore;
    const isLive = period.map(({ raw }) => raw).some(isMathString);
    return isLive ? 'live' : 'history';
  }

  set from(str: string) {
    const [start] = this.periodStore.period;
    if (str === start?.raw) return;
    set(this.periodStore.period, [0, 'raw'], str);
  }

  set to(str: string) {
    const [, end] = this.periodStore.period;
    if (str === end?.raw) return;
    set(this.periodStore.period, [1, 'raw'], str);
  }

  setupInitialCfg(from: string, to: string) {
    if (!from || !to) return;
    // http://localhost:3000/dashboard/66476ed6e4ce6f179b06fd9c?refresh=5s&from=1724837145&to=1724837407
    // http://localhost:3000/dashboard/66476ed6e4ce6f179b06fd9c?refresh=5s&from=now-5m&to=now

    const start = parse(from);
    const end = parse(to, true);

    if (!start.isValid() || !end.isValid()) return;

    // todo fix order if need
    this.periodStore.period = [start, end];
  }

  updatePeriod() {
    if (this.mode === 'history') return;
    this.period = this.period.map((itm) => itm.raw ? parse(itm.raw) : itm) as [WDay, WDay];
  }

  get unixPeriod() {
    const [start, end] = this.periodStore.period;
    const from = isString(start?.raw) ? parse(start.raw) : start;
    const to = isString(end?.raw) ? parse(end.raw, true) : end;
    return [from.unix(), to.unix()];
  }

  fixPeriod() {
    this.periodStore.period.forEach((row) => {
      delete row.raw;
    });
  }

  unixToDayjs([start, end]: [number, number]) {
    this.periodStore.period = [
      dayjs(start * 1000),
      dayjs(end * 1000),
    ];
  }

}
