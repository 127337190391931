import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { isArray } from 'lodash';

import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';
import { findExecutorHistoryByTracing } from "@/shared/lib/executorHistoryUtils";
import { DisplayMessageRequest, DisplayMessageResponse, ExecutorHistory } from '@/entities/Connect/types';
import { enrichList } from "../adapters/enrichExchangeList";
import { DisplayMessageStore } from '../model/DisplayMessageStore';
import { Tabs } from "../model/types";

@singleton()
export class DisplayMessageService {
  constructor(private displayMessageStore: DisplayMessageStore, private apiService: ApiService) {
    makeAutoObservable(this);
  }

  get isLoadingMessages() {
    return this.displayMessageStore.isLoadingMessages;
  }

  get blockTree() {
    return this.displayMessageStore.blockTree;
  }

  get exchangeList() {
    return this.displayMessageStore.exchangeList;
  }

  get relationSet() {
    return this.displayMessageStore.relationSet;
  }

  get canvas() {
    return this.displayMessageStore.canvas;
  }

  get messageNumber() {
    return this.displayMessageStore.messageNumber;
  }

  get messageTotal() {
    return this.displayMessageStore.messageTotal;
  }

  get selectedExecutorHistoryId() {
    return this.displayMessageStore.selectedExecutorHistoryId;
  }

  get selectedExecutorHistory() {
    return this.displayMessageStore.selectedExecutorHistory;
  }

  get isResendLoading() {
    return this.displayMessageStore.isResendLoading;
  }

  get activeTab() {
    return this.displayMessageStore.activeTab;
  }

  get maxHeight() {
    return this.displayMessageStore.maxHeight;
  }

  get maxWidth() {
    return this.displayMessageStore.maxWidth;
  }

  get executorHistory() {
    return this.displayMessageStore.executorHistory;
  }

  get updateData() {
    return this.displayMessageStore.updateData
  }

  setActiveTab(tab:Tabs) {
    this.displayMessageStore.activeTab = tab
  }

  setExecutorHistory(executorHistory: ExecutorHistory) {
    this.setMessages(executorHistory);
  }

  reset() {
    this.displayMessageStore.canvas = null;
    this.displayMessageStore.messages = null;
    this.displayMessageStore.exchangeList = null;
    this.displayMessageStore.relationSet = [];
    this.displayMessageStore.messageNumber = 0;
    this.displayMessageStore.messageTotal = 0;
    this.displayMessageStore.selectedExecutorHistoryId = null;
    this.displayMessageStore.selectedExecutorHistory = null;
    this.displayMessageStore.isResendLoading = false;
    this.displayMessageStore.activeTab = Tabs.tracing
    this.displayMessageStore.maxHeight = undefined;
    this.displayMessageStore.maxWidth = undefined;
    this.displayMessageStore.executorHistory = undefined
  }

  setMessages(executorHistory: ExecutorHistory | null) {
    if (!executorHistory) {
      this.displayMessageStore.exchangeList = null;
      this.displayMessageStore.relationSet = [];
      this.displayMessageStore.canvas = null;
      this.displayMessageStore.selectedExecutorHistory = null
      this.displayMessageStore.selectedExecutorHistoryId = null;
      return;
    }

    const { graphTrace } = executorHistory;
    const exchangeList = enrichList(graphTrace);
    this.displayMessageStore.exchangeList = normalize(exchangeList, 'id');
    this.displayMessageStore.relationSet = graphTrace.relationSet || [];
    this.displayMessageStore.canvas = normalize(graphTrace.canvas.elements, 'elementId');
    this.displayMessageStore.maxHeight = graphTrace.canvas.height;
    this.displayMessageStore.maxWidth = graphTrace.canvas.width;
    this.displayMessageStore.selectedExecutorHistory = executorHistory
    this.displayMessageStore.selectedExecutorHistoryId = executorHistory.id;
    if (!executorHistory.executorLogId) {
      this.displayMessageStore.activeTab = Tabs.tracing
    }
  }

  setBodyData(exchangeId: string, value: string, type: string) {
    if (type === "inputBody") {
      this.displayMessageStore.exchangeList!.entities[exchangeId].inputBody.outputBody = value
      this.displayMessageStore.exchangeList!.entities[exchangeId].inputBody.isSuccessDownload = true
    } else {
      this.displayMessageStore.exchangeList!.entities[exchangeId].body.outputBody = value
      this.displayMessageStore.exchangeList!.entities[exchangeId].body.isSuccessDownload = true
    }
  }

  async getMessages(blockId: string, body: DisplayMessageRequest) {
    this.displayMessageStore.isLoadingMessages = true;

    try {
      const response = await this.apiService.instance.post<DisplayMessageResponse>(
        `/editor/displayMessage/block/${blockId}`,
        { ...body }
      );

      runInAction(() => {
        const { content, number, totalElements } = response.data.executorHistoryPage;
        if (!isArray(content) || !content.length) {
          this.reset();
          return;
        }
        const [exHistory] = content;
        const { defaultExecutorHistoryIdByTracing } = exHistory;
        this.displayMessageStore.executorHistory = exHistory;
        const messages = defaultExecutorHistoryIdByTracing
          ? findExecutorHistoryByTracing(exHistory, defaultExecutorHistoryIdByTracing)
          : exHistory;
        this.setMessages(messages);
        this.displayMessageStore.messageNumber = number + 1;
        this.displayMessageStore.messageTotal = totalElements;
      });
    } catch (error) {
      notify.error('Не удалось получить трассировку сообщений');
      throw error;
    } finally {
      this.displayMessageStore.isLoadingMessages = false;
    }
  }

  async resendMessage(executorHistoryId: string) {
    this.displayMessageStore.isResendLoading = true;
    try {
      const response = await this.apiService.instance.post(`/editor/monitoring/execute/${executorHistoryId}`);
      runInAction(() => {
        this.setMessages(response.data)
      });
    } catch (error) {
      notify.error('Не удалось переотправить сообщение');
      throw error;
    } finally {
      this.displayMessageStore.isResendLoading = false;
    }
  }

  async downloadBodyData(exchangeId: string, bodyDataComponentId: string, type: string) {
    try {
      const response = await this.apiService.instance.get(`/editor/bodyData/${bodyDataComponentId}`);

      runInAction(() => {
        this.setBodyData(exchangeId, response.data.data, type)
        this.displayMessageStore.updateData = new Date().getTime()
      });
    } catch (error) {
      notify.error('Не удалось выполнить загрузку');
      throw error;
    }
  }
}
