import * as React from 'react';
import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import _, { pick } from 'lodash';
import { Controller, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import { FieldPassword } from '@/shared/ui/Fields/components/FieldPassword/FieldPassword';
import { FieldCron } from '@/shared/ui/Fields/components/FieldCron/FieldCron';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { Parameter } from '@/entities/Connect/types';
import { FieldCode } from '@/shared/ui/Fields/components/FieldCode/FieldCode';
import { MappingData } from '@/shared/ui/Fields/components/MappingData/MappingData';
import { ChoosePythonBeanForm } from '@/features/ChoosePythonScript';
import { AnimatedBox } from '@/shared/ui';
import { Connector1cTree } from '@/entities/Connector1c/ui/Connector1cTree';
import { UpdateSkeletonWrapper } from '@/features/UpdateSkeleton';
import { FieldAutocomplete } from '@/shared/ui/Fields/components/FieldAutocomplete/FieldAutocomplete';
import { SmartTypography } from '@/shared/ui/Fields/components/SmartTypography/SmartTypography';
import { UploadFiles } from '@/features/UploadFiles/ui/UploadFiles';
import { FieldAccordion } from '@/shared/ui/Fields/components/FieldAccordion/FieldAccordion';
import { FieldSwitchWithParameter } from '@/shared/ui/Fields/components/FieldSwitchWithParameter/FieldSwitchWithParameter';
import { FieldListString } from '@/shared/ui/Fields/components/FieldListString/FieldListString';
import { FieldTabs } from '@/shared/ui/Fields/components/FieldTabs/FieldTabs';
import { BlockSelectContainer } from '@/shared/ui/Fields/containers/BlockSelectContainer';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { amendDraggable } from '@/shared/lib/extendDndkit';
import { FieldInputNumber } from '@/shared/ui/Fields/components/FieldInputNumber/FieldInputNumber';
import { FieldObject } from '@/shared/ui/Fields/components/FieldObject/FieldObject';

import { FieldList } from './components/FieldList';
import { RoleSelectContainer } from './containers/RoleSelectContainer';
import { SelectByParameter } from './components/SelectByParameter/SelectByParameter';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { FieldRadioGroup } from '@/shared/ui/Fields/components/FieldRadioGroup';

export interface FieldsProps {
  parameters: Parameter[];
  namePrefix?: string;
  register: any;
  control: any;
  errors: any;
  warnings?: any;
  setValue: UseFormSetValue<any>;
  id?: string;
  getValues: UseFormGetValues<any>;
  defaultValue?: any;
  isNamePrefixProps?: boolean;

  updateSkeleton(values: any): void;
}

export interface FieldProps
  extends Omit<Parameter, 'isSendSkeletonForEnrichByButton' | 'isSendSkeletonForEnrichByDebounce'> {
  register?: any;
  control?: any;
  error: any;
  warning?: any;
  animationProps?: typeof ANIMATION_PROPS;
  id?: string;
  invertChecked?: boolean;
  onChange?: () => void;
}

export interface FieldWhichRenderInsideFieldsProps
  extends Omit<FieldsProps, 'parameters'>,
    Pick<FieldProps, 'animationProps' | 'error'> {
  parameter: Parameter;
  defaultValue?: any;
}

const ANIMATION_PROPS = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

/**
 * Компонент генерации формы по параметрам с бэка
 */
export const Fields: FC<FieldsProps> = observer(
  ({
    parameters,
    namePrefix = 'params',
    isNamePrefixProps = false,
    control,
    register,
    errors,
    warnings,
    setValue,
    getValues,
    updateSkeleton,
    defaultValue,
  }) => (
    <>
      {parameters &&
        parameters.map((parameter) => {
          const name = `${namePrefix}.${parameter.name}`;
          const error = _.get(errors, name, '');
          const warning = _.get(warnings, name, '');
          const draggable = _.get(parameter, 'props.draggable', false);

          switch (parameter.type) {
            case 'RadioGroup': {
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  render={(onChange) => {
                    return (
                      <AnimatedBox {...ANIMATION_PROPS} width='100%' sx={parameter.style}>
                        <FieldRadioGroup
                          {...parameter}
                          groups={parameter?.parameterList}
                          error={error}
                          name={name}
                          control={control}
                          register={register}
                          onChange={onChange}
                        />
                      </AnimatedBox>
                    );
                  }}
                />
              );
            }

            case 'Select': {
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  render={(onChange) => (
                    <AnimatedBox {...ANIMATION_PROPS} width='100%' sx={parameter.style}>
                      <FieldSelect
                        {...parameter}
                        options={parameter.options || []}
                        error={error}
                        name={name}
                        control={control}
                        register={register}
                        onChange={onChange}
                      />
                    </AnimatedBox>
                  )}
                />
              );
            }
            case 'DoubleField': {
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  sx={parameter.style}
                  render={(onChange) => (
                    <AnimatedBox {...ANIMATION_PROPS} width='100%'>
                      <FieldInputNumber
                        {...parameter}
                        key={name}
                        control={control}
                        register={register}
                        error={error}
                        warning={warning}
                        name={name}
                        onChange={onChange}
                      />
                    </AnimatedBox>
                  )}
                />
              );
            }

            case 'NumberField': {
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  sx={parameter.style}
                  render={(onChange) => (
                    <AnimatedBox {...ANIMATION_PROPS} width='100%'>
                      <FieldInputNumber
                        {...parameter}
                        key={name}
                        control={control}
                        register={register}
                        error={error}
                        warning={warning}
                        name={name}
                        onChange={onChange}
                      />
                    </AnimatedBox>
                  )}
                />
              );
            }

            case 'Label': {
              return (
                <Typography className='preWrap'>
                  <div
                    dangerouslySetInnerHTML={{ __html: `${parameter.label}` }}
                    {...amendDraggable(true)}
                  />
                </Typography>
              );
            }

            case 'TextField': {
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  sx={parameter.style}
                  render={(onChange) => (
                    <AnimatedBox {...ANIMATION_PROPS} width='100%'>
                      <FieldInput
                        {...parameter}
                        key={name}
                        register={register}
                        error={error}
                        warning={warning}
                        name={name}
                        onChange={onChange}
                      />
                    </AnimatedBox>
                  )}
                />
              );
            }

            case 'TextFieldWithText': {
              return (
                <Stack gap={2} sx={parameter.style}>
                  <UpdateSkeletonWrapper
                    isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                    isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                    getValues={getValues}
                    updateSkeleton={updateSkeleton}
                    render={(onChange) => (
                      <AnimatedBox {...ANIMATION_PROPS} flex={1}>
                        <FieldInput
                          {...parameter}
                          register={register}
                          error={error}
                          name={name}
                          onChange={onChange}
                        />
                      </AnimatedBox>
                    )}
                  />
                  {parameter.bottomText && (
                    <AnimatedBox {...ANIMATION_PROPS}>
                      <Controller
                        control={control}
                        name={name}
                        render={({ field }) => (
                          <SmartTypography parameter={parameter} field={field} />
                        )}
                      />
                    </AnimatedBox>
                  )}
                </Stack>
              );
            }

            case 'SwitchWithParameter': {
              return (
                <FieldSwitchWithParameter
                  parameter={{ ...parameter, name }}
                  error={error}
                  control={control}
                  register={register}
                  namePrefix={isNamePrefixProps ? namePrefix : undefined}
                  isNamePrefixProps={isNamePrefixProps}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  defaultValue={defaultValue}
                  updateSkeleton={updateSkeleton}
                />
              );
            }
            case 'Accordion': {
              return (
                <FieldAccordion
                  parameter={{ ...parameter, name }}
                  control={control}
                  namePrefix={isNamePrefixProps ? namePrefix : undefined}
                  isNamePrefixProps={isNamePrefixProps}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  defaultValue={defaultValue}
                  updateSkeleton={updateSkeleton}
                  error={error}
                />
              );
            }
            case 'SelectByParameter': {
              return (
                <SelectByParameter
                  parameter={{ ...parameter, name }}
                  register={register}
                  control={control}
                  errors={errors}
                  error={error}
                  isNamePrefixProps={isNamePrefixProps}
                  namePrefix={namePrefix}
                  setValue={setValue}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                />
              );
            }

            case 'Switch': {
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  sx={parameter.style}
                  render={(onChange) => (
                    <AnimatedBox {...ANIMATION_PROPS} sx={parameter.style}>
                      <FieldSwitch
                        {...parameter}
                        error={error}
                        name={name}
                        control={control}
                        onChange={onChange}
                      />
                    </AnimatedBox>
                  )}
                />
              );
            }
            case 'Object': {
              const nameWithPrefix = namePrefix === 'params' && `paramsObject.${parameter.name}`;
              return (
                <AnimatedBox {...ANIMATION_PROPS} sx={parameter.style}>
                  <FieldObject
                    name={parameter.name}
                    description={parameter.description}
                    label={parameter.label || ''}
                    control={control}
                    defaultValue={parameter.defaultValue}
                    renderElement={() => (
                      <Fields
                        parameters={parameter.parameterList || []}
                        namePrefix={nameWithPrefix}
                        control={control}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        getValues={getValues}
                        updateSkeleton={updateSkeleton}
                      />
                    )}
                    draggable={draggable}
                    {...parameter.props}
                  />
                </AnimatedBox>
              );
            }

            case 'ListObject': {
              const nameWithPrefix =
                namePrefix === 'params' ? `paramsObject.${parameter.name}` : name;
              const prps = pick(parameter, ['description', 'label', 'defaultValue']);
              return (
                <AnimatedBox {...ANIMATION_PROPS} sx={parameter.style}>
                  <FieldList
                    name={nameWithPrefix}
                    control={control}
                    renderElement={(index) => (
                      <Stack direction='row' gap={2.5} sx={parameter.childrenBoxStyle}>
                        <Fields
                          parameters={parameter.parameterList || []}
                          namePrefix={`${nameWithPrefix}.${index}`}
                          control={control}
                          register={register}
                          errors={errors}
                          setValue={setValue}
                          getValues={getValues}
                          updateSkeleton={updateSkeleton}
                        />
                      </Stack>
                    )}
                    draggable={draggable}
                    getValues={getValues}
                    {...prps}
                    {...parameter.props}
                  />
                </AnimatedBox>
              );
            }

            case 'SelectRoles': {
              const nameWithPrefix =
                namePrefix === 'params' ? `paramsObject.${parameter.name}` : name;
              return (
                <AnimatedBox width='100%' {...ANIMATION_PROPS}>
                  <RoleSelectContainer
                    error={error}
                    {...parameter}
                    control={control}
                    register={register}
                    name={nameWithPrefix}
                  />
                </AnimatedBox>
              );
            }

            case 'SelectBlocks': {
              const nameWithPrefix =
                namePrefix === 'params' ? `paramsObject.${parameter.name}` : name;
              return (
                <AnimatedBox width='100%' {...ANIMATION_PROPS}>
                  <BlockSelectContainer
                    error={error}
                    {...parameter}
                    control={control}
                    register={register}
                    name={nameWithPrefix}
                  />
                </AnimatedBox>
              );
            }

            case 'Autocomplete': {
              let nameWithPrefix: string;
              if (parameter.props?.useParams) {
                nameWithPrefix = name;
              } else {
                nameWithPrefix = namePrefix === 'params' ? `paramsObject.${parameter.name}` : name;
              }
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  sx={parameter.style}
                  render={(onChange) => (
                    <Stack gap={2} flexGrow={1}>
                      <AnimatedBox width='100%' {...ANIMATION_PROPS}>
                        <FieldAutocomplete
                          error={error?.message}
                          {...parameter}
                          options={parameter.options || []}
                          control={control}
                          register={register}
                          name={nameWithPrefix}
                          onChange={onChange}
                        />
                      </AnimatedBox>
                      {parameter.bottomText && (
                        <AnimatedBox {...ANIMATION_PROPS}>
                          <Controller
                            control={control}
                            name={name}
                            render={({ field }) => (
                              <SmartTypography parameter={parameter} field={field} />
                            )}
                          />
                        </AnimatedBox>
                      )}
                    </Stack>
                  )}
                />
              );
            }

            case 'Password': {
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  sx={parameter.style}
                  render={(onChange) => (
                    <AnimatedBox width='100%' {...ANIMATION_PROPS}>
                      <FieldPassword
                        error={error}
                        {...amendDraggable(true)}
                        {...parameter}
                        register={register}
                        name={name}
                        onChange={onChange}
                      />
                    </AnimatedBox>
                  )}
                />
              );
            }

            case 'Cron': {
              return (
                <AnimatedBox {...ANIMATION_PROPS} sx={parameter.style}>
                  <FieldCron
                    {...parameter}
                    error={error}
                    control={control}
                    name={name}
                    getValues={getValues}
                  />
                </AnimatedBox>
              );
            }

            case 'ListString': {
              const nameWithPrefix =
                namePrefix === 'params' ? `paramsObject.${parameter.name}` : name;
              return (
                <UpdateSkeletonWrapper
                  isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
                  isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
                  getValues={getValues}
                  updateSkeleton={updateSkeleton}
                  sx={parameter.style}
                  render={(onChange) => (
                    <AnimatedBox width='100%' {...ANIMATION_PROPS} sx={parameter.style}>
                      <FieldListString
                        error={error}
                        {...parameter}
                        name={nameWithPrefix}
                        control={control}
                        onChange={onChange}
                      />
                    </AnimatedBox>
                  )}
                />
              );
            }

            case 'Code': {
              return (
                <AnimatedBox {...ANIMATION_PROPS} sx={parameter.style}>
                  <FieldCode
                    error={error}
                    {...parameter}
                    name={name}
                    control={control}
                    id='handlerMessage'
                  />
                </AnimatedBox>
              );
            }

            case 'Group': {
              return (
                <Box sx={parameter.style}>
                  <Fields
                    parameters={parameter.parameterList || []}
                    register={register}
                    control={control}
                    errors={errors}
                    warnings={warnings}
                    setValue={setValue}
                    namePrefix={namePrefix}
                    isNamePrefixProps={isNamePrefixProps}
                    getValues={getValues}
                    updateSkeleton={updateSkeleton}
                  />
                </Box>
              );
            }
            case 'MappingData': {
              const nameWithPrefix =
                namePrefix === 'params' ? `paramsObject.${parameter.name}` : name;

              return (
                <MappingData
                  name={nameWithPrefix}
                  register={register}
                  control={control}
                  draggable={draggable}
                />
              );
            }

            case 'FileField': {
              const nameWithPrefix =
                namePrefix === 'params' ? `paramsObject.${parameter.name}` : name;
              return (
                <Box marginY='1rem'>
                  <Controller
                    control={control}
                    name={nameWithPrefix}
                    render={({ field }) => (
                      <UploadFiles
                        attachments={field.value}
                        parameter={parameter}
                        onChange={(attachment) => field.onChange(attachment)}
                      />
                    )}
                  />
                </Box>
              );
            }

            case 'Tabs': {
              return (
                <Stack gap={2} flexGrow={1}>
                  <FieldTabs
                    parameters={parameter.parameterList || []}
                    register={register}
                    control={control}
                    errors={errors}
                    warnings={warnings}
                    setValue={setValue}
                    namePrefix={namePrefix}
                    isNamePrefixProps={isNamePrefixProps}
                    getValues={getValues}
                    updateSkeleton={updateSkeleton}
                  />
                </Stack>
              );
            }

            case 'ChoosePythonScript': {
              return (
                <Controller
                  name={name}
                  control={control}
                  render={({ field }) => (
                    <ChoosePythonBeanForm
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />
                  )}
                />
              );
            }
            case 'Tree': {
              const nameWithPrefix =
                namePrefix === 'params' ? `paramsObject.${parameter.name}` : name;

              return (
                <Controller
                  control={control}
                  name={nameWithPrefix}
                  render={({ field }) => (
                    <div {...amendDraggable(true)}>
                      <Connector1cTree
                        parameters={parameter.parameterList!}
                        defaultValue={field.value}
                        /* eslint-disable-next-line react/jsx-handler-names */
                        onChange={field.onChange}
                      />
                    </div>
                  )}
                />
              );
            }
          }
        })}
    </>
  )
);
