import { Edge, Node } from "reactflow";

export enum Tabs {
    tracing = 'tracing',
    logs = 'logs',
}

export interface MessageNodeProps {
    id: string;
    processorType: string;
    processorTypeLocale: string | undefined;
    processorName: string;
    value: string;
    context?: string;
    files?: any;
    headers?: { [key: string]: string };
    queryParams?: { [key: string]: string };
    pathParams?: { [key: string]: string };
    exception?: string;
    stackTrace?: string;
    messageId?: string;
    dateExecute?: string;
    timeExecute?: number;
    onDownloadBody?: any;
    valueSize?: number;
}

export interface MessageTree {
    nodes: Node<MessageNodeProps>[];
    edges: Edge[];
}