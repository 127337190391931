import { Interval } from '@/entities/Dashboard/types';

export const quickOptions = [
  { from: 'now-1m', to: 'now', display: 'Последняя 1 минута' },
  { from: 'now-3m', to: 'now', display: 'Последние 3 минуты' },
  { from: 'now-5m', to: 'now', display: 'Последние 5 минут' },
  { from: 'now-15m', to: 'now', display: 'Последние 15 минут' },
  { from: 'now-30m', to: 'now', display: 'Последние 30 минут' },
  { from: 'now-1h', to: 'now', display: 'Последний 1 час' },
  { from: 'now-6h', to: 'now', display: 'Последние 6 часов' },
  { from: 'now-12h', to: 'now', display: 'Последние 12 часов' },
  { from: 'now-24h', to: 'now', display: 'Последние 24 часа' },
  { from: 'now-1d', to: 'now', display: 'Последний 1 день' },
  { from: 'now-2d', to: 'now', display: 'Последние 2 дня' },
  { from: 'now-7d', to: 'now', display: 'Последние 7 дней' },
  { from: 'now-30d', to: 'now', display: 'Последние 30 дней' },
  { from: 'now-1M', to: 'now', display: 'Последний 1 месяц' },
  { from: 'now-90d', to: 'now', display: 'Последние 90 дней' },
  { from: 'now-6M', to: 'now', display: 'Последние 6 месяцев' },
  { from: 'now-1y', to: 'now', display: 'Последний 1 год' },
  { from: 'now-2y', to: 'now', display: 'Последние 2 года' },
  { from: 'now-5y', to: 'now', display: 'Последние 5 лет' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Вчера' },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Позавчера' },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'Этот день на прошлой неделе' },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Прошлая неделя' },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Прошлый месяц' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Прошлый год' },
  { from: 'now/d', to: 'now/d', display: 'Сегодня' },
  { from: 'now/d', to: 'now', display: 'С начала дня' },
  { from: 'now/w', to: 'now/w', display: 'Эта неделя' },
  { from: 'now/w', to: 'now', display: 'С начала недели' },
  { from: 'now/M', to: 'now/M', display: 'Этот месяц' },
  { from: 'now/M', to: 'now', display: 'С начала месяца' },
  { from: 'now/y', to: 'now/y', display: 'Этот год' },
  { from: 'now/y', to: 'now', display: 'С начала года' },
  // { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: 'Previous fiscal quarter' },
  // { from: 'now-1y/fy', to: 'now-1y/fy', display: 'Previous fiscal year' },
  // { from: 'now/fQ', to: 'now', display: 'This fiscal quarter so far' },
  // { from: 'now/fQ', to: 'now/fQ', display: 'This fiscal quarter' },
  // { from: 'now/fy', to: 'now', display: 'This fiscal year so far' },
  // { from: 'now/fy', to: 'now/fy', display: 'This fiscal year' },
];

export const refreshOptions = [
  {
    key: 'off',
    label: 'откл',
  },
  {
    key: '5s',
    label: '5с',
    value: 5,
  },
  {
    key: '10s',
    label: '10с',
    value: 10,
  },
  {
    key: '30s',
    label: '30с',
    value: 30,
  },
  {
    key: '1m',
    label: '1м',
    value: 60,
  },
  {
    key: '5m',
    label: '5м',
    value: 5 * 60,
  },
  {
    key: '15m',
    label: '15м',
    value: 15 * 60,
  },
  {
    key: '30m',
    label: '30м',
    value: 30 * 60,
  },
  {
    key: '1h',
    label: '1ч',
    value: 60 * 60,
  },
  {
    key: '2h',
    label: '2ч',
    value: 2 * 60 * 60,
  },
  {
    key: '1d',
    label: '1д',
    value: 24 * 60 * 60,
  },
];

export const baseQntDots = 120; // points per graph line

export const intervalMap: { [key:string]: Interval } = {
  1: {
    key: '1s',
    label: '1с',
    value: 1,
  },
  5: {
    key: '5s',
    label: '5с',
    value: 5,
  },
  10: {
    key: '10s',
    label: '10с',
    value: 10,
  },
  15: {
    key: '15s',
    label: '15с',
    value: 15,
  },
  30: {
    key: '30s',
    label: '30с',
    value: 30,
  },
  45: {
    key: '45s',
    label: '45с',
    value: 45,
  },
  60: {
    key: '1m',
    label: '1м',
    value: 60,
  },
  300: {
    key: '5m',
    label: '5м',
    value: 5 * 60,
  },
  600: {
    key: '10m',
    label: '10м',
    value: 10 * 60,
  },
  900: {
    key: '15m',
    label: '15м',
    value: 15 * 60,
  },
  1800: {
    key: '30m',
    label: '30м',
    value: 30 * 60,
  },
  2700: {
    key: '45m',
    label: '45м',
    value: 45 * 60,
  },
  3600: {
    key: '1h',
    label: '1ч',
    value: 60 * 60,
  },
  10800: {
    key: '3h',
    label: '3ч',
    value: 3 * 60 * 60,
  },
  21600: {
    key: '6h',
    label: '6ч',
    value: 6 * 60 * 60,
  },
  43200: {
    key: '12h',
    label: '12ч',
    value: 12 * 60 * 60,
  },
  86400: {
    key: '1d',
    label: '1д',
    value: 24 * 60 * 60,
  },
  253800: {
    key: '3d',
    label: '3д',
    value: 3 * 24 * 60 * 60,
  },
  592200: {
    key: '7d',
    label: '7д',
    value: 7 * 24 * 60 * 60,
  },
  1184400: {
    key: '14d',
    label: '14д',
    value: 14 * 24 * 60 * 60,
  },
  2592000: {
    key: '1M',
    label: '1мес',
    value: 30 * 24 * 60 * 60,
  },
  7776000: {
    key: '3M',
    label: '3мес',
    value: 3 * 30 * 24 * 60 * 60,
  },
  15552000: {
    key: '6M',
    label: '6мес',
    value: 6 * 30 * 24 * 60 * 60,
  },
};
